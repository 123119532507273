import React, { useState, useEffect, useRef } from "react";
import { styled, useTheme } from '@mui/material/styles';
import {
    Box,
    Drawer as MuiDrawer,
    AppBar as MuiAppBar,
    Toolbar,
    List,
    CssBaseline,
    Collapse,
    Typography,
    Divider,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import {
    Menu as MenuIcon,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from "../pages/login/OAuth";
import { FaCoins, FaBuilding, FaUserTie, FaShoppingCart, FaClipboardList, FaTruck} from 'react-icons/fa';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: open ? drawerWidth : 0, 
    width: open ? `calc(100% - ${drawerWidth}px)` : '100%', 
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const menuItems = [
    {
        name: 'Clientes', icon: <FaUserTie />, path: '/clients', subMenu: [
            { name: 'Nuevo cliente', path: '/clients/new', allowedRol: ["admin", 'superadmin'] },
            { name: 'Ver clientes', path: '/clients/list', allowedRol: ["admin", 'superadmin'] },
            { name: 'Ver facturas', path: '/clients/invoices', allowedRol: ["admin", 'superadmin'] },
            { name: 'Ver retenciones', path: '/clients/retentions', allowedRol: ["admin", 'superadmin'] },
        ]
    },
    {
        name: 'Proveedores', icon: <FaTruck />, path: '/providers', subMenu: [
            { name: 'Nuevo proveedor', path: '/providers/new', allowedRol: ["admin", 'superadmin'] },
            { name: 'Ver proveedores', path: '/providers/list', allowedRol: ["admin", 'superadmin'] },
            { name: 'Ver facturas', path: '/providers/invoices', allowedRol: ["admin", 'superadmin'] },
            { name: 'Ver retenciones', path: '/providers/retentions', allowedRol: ["admin", 'superadmin'] },
        ]
    },
    {
        name: 'Obras', icon: <FaBuilding />, path: '/buildings', subMenu: [
            { name: 'Nueva obra', path: '/buildings/new', allowedRol: ["admin", 'superadmin'] },
            { name: 'Ver obras', path: '/buildings/list', allowedRol: ["admin", 'superadmin'] },
        ]
    },
    {
        name: 'Ofertas', icon: <FaClipboardList />, path: '/offers', subMenu: [
            { name: 'Nueva oferta', path: '/offers/new', allowedRol: ["admin", 'superadmin'] },
            { name: 'Ver ofertas', path: '/offers/list', allowedRol: ["admin", 'superadmin'] },
        ]
    },
    {
        name: 'Pedidos', icon: <FaShoppingCart />, path: '/orders', subMenu: [
            { name: 'Nuevo pedido', path: '/orders/new', allowedRol: ["admin", 'superadmin'] },
            { name: 'Ver pedidos', path: '/orders/list', allowedRol: ["admin", 'superadmin'] },
        ]
    },
    {
        name: 'Facturas', icon: <FaCoins />, path: '/invoices', subMenu: [
            { name: 'Ver facturas', path: '/invoices/list', allowedRol: ["admin", 'superadmin'] },
        ]
    },
];

const routesMapping = [
    // Autenticación
    { path: '/sign-in', title: 'Iniciar Sesión' },
    { path: '/sign-up', title: 'Registro' },
    { path: '/reset-pass', title: 'Restablecer Contraseña' },
    { path: '/reset-pass/:token', title: 'Nueva Contraseña' },

    // Clientes
    { path: '/clients/new', title: 'Nuevo Cliente' },
    { path: '/clients/list', title: 'Listado de Clientes' },
    { path: '/clients/invoices', title: 'Facturas de Clientes' },
    { path: '/clients/retentions', title: 'Retenciones de Clientes' },
    { path: '/clients/update/:id', title: 'Detalles Cliente' },

    // Proveedores
    { path: '/providers/new', title: 'Nuevo Proveedor' },
    { path: '/providers/list', title: 'Listado de Proveedores' },
    { path: '/providers/invoices', title: 'Facturas de Proveedores' },
    { path: '/providers/retentions', title: 'Retenciones de Proveedores' },
    { path: '/providers/update/:id', title: 'Detalles Proveedor' },

    // Obras
    { path: '/buildings/new', title: 'Nueva Obra' },
    { path: '/buildings/list', title: 'Listado de Obras' },
    { path: '/buildings/update/:id', title: 'Detalles Obra' },

    // Ofertas
    { path: '/offers/new', title: 'Nueva Oferta' },
    { path: '/offers/list', title: 'Listado de Ofertas' },
    { path: '/offers/update/:id', title: 'Detalles Oferta' },

    // Pedidos
    { path: '/orders/new', title: 'Nuevo Pedido' },
    { path: '/orders/list', title: 'Listado de Pedidos' },
    { path: '/orders/update/:id', title: 'Detalles Pedido' },

    // Facturas
    { path: '/invoices/list', title: 'Listado de Facturas' },
    { path: '/invoices/update/:id', title: 'Detalles Factura' },

    // Certificaciones
    { path: '/certifications/update/:id', title: 'Detalles Certificación' },

    // Albaranes
    { path: '/delivery_notes/update/:id', title: 'Detalles Albarán' },
];


export default function MiniDrawer() {
    const theme = useTheme();
    const [isOpen, setIsOpen] = React.useState(false);
    const { authenticated, logout, userRole } = useAuth()
    const drawerRef = useRef(null);
    const location = useLocation();

    const [openSubMenu, setOpenSubMenu] = useState(null);

    const getPageTitle = () => {
        let title = "Inicio";

        const matchPath = (pattern, path) => {
            const regex = new RegExp(`^${pattern.replace(/:\w+/g, '[^/]+')}$`);
            return regex.test(path);
        };

        routesMapping.forEach(route => {
            if (matchPath(route.path, location.pathname)) {
                title = route.title;
            }
        });

        return title;
    };


    const handleSubMenuClick = (path) => {
        setOpenSubMenu(openSubMenu === path ? null : path);
        handleDrawerOpen();
    };

    const handleDrawerOpen = () => {
        setIsOpen(true);
    };

    useEffect(() => {
        if (!authenticated) {
            handleDrawerClose();
        }
    }, [authenticated]);

    const handleDrawerClose = () => {
        setIsOpen(false);
        setOpenSubMenu(false);
    };

    const handleClick = () => {
        setOpenSubMenu(false)
        setIsOpen(false);
    };

    const handleClickOutside = (event) => {
        if (drawerRef.current && !drawerRef.current.contains(event.target)) {
            handleDrawerClose();
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={isOpen} sx={{ backgroundColor: '#E66136' }}>
                <Toolbar>
                    {authenticated && (

                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(isOpen && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Typography variant="h6" noWrap component={Link} to="/" color="inherit" sx={{ flexGrow: 1, textDecoration: 'none' }}>
                        LOGIMSA - {getPageTitle()}
                    </Typography>

                    {authenticated ? (
                        <Typography variant="body1" noWrap color="inherit" component={Link} to="/" onClick={logout} sx={{ textDecoration: 'none' }}>
                            Cerrar sesión
                        </Typography>

                    ) : (
                        <></>
                    )}
                </Toolbar>
            </AppBar>
            {authenticated && (

                <Drawer variant="permanent" open={isOpen} ref={drawerRef}>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />

                    <List>
                        {menuItems.map((item) => (
                            <div key={item.name}>
                                <ListItem disablePadding sx={{ display: 'block' }}>
                                    {item.subMenu ? (
                                        <ListItemButton
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: isOpen ? 'initial' : 'center',
                                                px: 3.5,
                                            }}
                                            onClick={() => handleSubMenuClick(item.path)}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: isOpen ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} sx={{ opacity: isOpen ? 1 : 0 }} />
                                            {isOpen ? <ExpandMoreIcon /> : <></>}
                                        </ListItemButton>
                                    ) : (
                                        <ListItemButton
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: isOpen ? 'initial' : 'center',
                                                px: 2.5,
                                            }}
                                            component={Link}
                                            to={item.path}
                                            onClick={() => handleClick(item.name)}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: isOpen ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} sx={{ opacity: isOpen ? 1 : 0 }} />
                                        </ListItemButton>
                                    )}
                                </ListItem>
                                {item.subMenu && (
                                    <Collapse in={openSubMenu === item.path} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {item.subMenu.map((subItem) => {
                                                const showItem = subItem.allowedRol.includes(userRole);
                                                return showItem && (
                                                    <ListItem key={subItem.name} disablePadding>
                                                        <ListItemButton
                                                            sx={{
                                                                minHeight: 48,
                                                                justifyContent: 'center',
                                                                px: 4,
                                                            }}
                                                            component={Link}
                                                            to={subItem.path}
                                                            onClick={() => handleClick(item.name)}
                                                        >
                                                            <ListItemText primary={subItem.name} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </Collapse>
                                )}
                            </div>
                        ))}
                    </List>
                </Drawer>
            )}
        </Box>
    );
}