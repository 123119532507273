import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useAuth } from "../pages/login/OAuth";

const UploadDocumentButton = ({ to, id, fetch, showDisableDoc }) => {
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadError, setUploadError] = useState(null);
    const [loading, setLoading] = useState(false);
    const { api } = useAuth();

    const handleUpload = async () => {
        if (!selectedFile) {
            setUploadError("Por favor selecciona un archivo");
            return;
        }

        const formData = new FormData();
        formData.append('document', selectedFile);
        formData.append(to, id);

        try {
            setLoading(true);
            const result = await api().post(`/documents/add`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            if (result.status < 300) {
                alert('Documento subido correctamente');
                fetch();
                setUploadModalOpen(false);
                setSelectedFile(null);
                setUploadError(null);
            } else {
                setUploadError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            alert(`Error: ${error.response?.data?.detail || error.message}`)
        } finally {
            setLoading(false);
        }
    };

    const handleDisableDocument = async () => {
        const formData = new FormData();
        formData.append(to, id);

        try {
            setLoading(true);
            const result = await api().patch(`/documents/disable_by_object_id`, formData);

            if (result.status < 300) {
                alert('Documento eliminado correctamente');
                fetch();
            }
        } catch (error) {
            alert(`Error: ${error.response?.data?.detail || error.message}`)
        } finally {
            setLoading(false);
            setConfirmDeleteOpen(false);
        }
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => setUploadModalOpen(true)}
                startIcon={<AddIcon />}
                sx={{ marginRight: '10px' }}
            >
                doc
            </Button>

            {showDisableDoc ? (
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setConfirmDeleteOpen(true)}
                    size="small"
                    sx={{ marginRight: '10px' }}
                    style={{ backgroundColor: 'red' }}>
                    Eliminar doc
                </Button>
            ) : (
                <></>
            )}

            <Dialog open={uploadModalOpen} onClose={() => setUploadModalOpen(false)}>
                <DialogTitle>Subir documento (pdf, word, excel o imagen hasta 20mb)</DialogTitle>
                <DialogContent>
                    <input type="file" onChange={handleFileChange} />
                    {uploadError && (
                        <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                            {uploadError}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setUploadModalOpen(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleUpload}
                        color="primary"
                        variant="contained"
                        disabled={loading}
                    >
                        {loading ? 'Subiendo...' : 'Enviar'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={confirmDeleteOpen} onClose={() => setConfirmDeleteOpen(false)}>
                <DialogTitle>Confirmar eliminación</DialogTitle>
                <DialogContent>
                    <Typography>¿Estás seguro de que deseas eliminar este documento?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDeleteOpen(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleDisableDocument}
                        color="secondary"
                        variant="contained"
                        style={{ backgroundColor: 'red' }}
                        disabled={loading}
                    >
                        {loading ? 'Eliminando...' : 'Eliminar'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UploadDocumentButton;
