import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../login/OAuth";
import {
    FormControl,
    InputLabel,
    Autocomplete,
    MenuItem,
    Select,
    TextField,
    Button,
    Grid,
    CircularProgress,
    Typography,
} from "@mui/material";
import InvoiceWithTotalsTable from "../../components/InvoiceWithTotalsTable";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import "dayjs/locale/es";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

function ListInvoices() {
    const { api } = useAuth();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const [apply, setApply] = useState(false);
    const [reset, setReset] = useState(false);

    const [status, setStatus] = useState(null);
    const [code, setCode] = useState(null);
    const [externalProviderId, setExternalProviderId] = useState(null);
    const [startInvoiceDate, setStartInvoiceDate] = useState(null);
    const [endInvoiceDate, setEndInvoiceDate] = useState(null);

    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);

    const [providers, setProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(null);

    const [buildings, setBuildings] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState(null);

    const fetchClients = useCallback(async () => {
        try {
            setLoading(true);

            const result = await api().get("/clients/");

            setClients(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api]);

    const fetchProviders = useCallback(async () => {
        try {
            setLoading(true);

            const result = await api().get("/providers/");

            setProviders(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api]);

    const fetchBuildings = useCallback(async () => {
        try {
            setLoading(true);

            const result = await api().get("/buildings/");

            setBuildings(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api]);

    useEffect(() => {
        fetchClients();
        fetchProviders();
        fetchBuildings();
    }, []);

    const handleStatusChange = (event) => {
        const { value } = event.target;
        setStatus(value);
    };

    const handleApplyFilters = () => {
        setApply(true);
        setTimeout(() => setApply(false), 0);
    };

    const handleResetFilters = () => {
        setStatus(null);
        setSelectedClient(null);
        setSelectedBuilding(null);
        setSelectedProvider(null);
        setCode(null);
        setExternalProviderId(null);
        setReset(true);
        setStartInvoiceDate(null);
        setEndInvoiceDate(null);
        setTimeout(() => setReset(false), 0);
    };

    const handleClientChange = (event, newValue) => {
        setSelectedClient(newValue);
    };

    const handleProviderChange = (event, newValue) => {
        setSelectedProvider(newValue);
    };

    const handleBuildingChange = (event, newValue) => {
        setSelectedBuilding(newValue);
    };

    const exportAllInvoices = async () => {
        try {
            const params = {};

            if (code) params.code = code;
            if (externalProviderId) params.external_provider_id = externalProviderId;
            if (status) params.invoice_status = status;
            if (selectedBuilding) params.building_id = selectedBuilding.id;
            if (selectedClient) params.client_id = selectedClient.id;
            if (selectedProvider) params.provider_id = selectedProvider.id;
            if (startInvoiceDate) {
                const startDateMidday = dayjs(startInvoiceDate).hour(12).minute(0).second(0);
                params.start_invoice_date = startDateMidday.utc().format('YYYY-MM-DD');
            }
            if (endInvoiceDate) {
                const endDateMidday = dayjs(endInvoiceDate).hour(12).minute(0).second(0);
                params.end_invoice_date = endDateMidday.utc().format('YYYY-MM-DD');
            }

            const response = await api().post(`/invoices/export`, params, { responseType: 'blob' });

            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;

            const generateFilename = () => {
                const now = new Date();
                const year = now.getFullYear();
                const month = String(now.getMonth() + 1).padStart(2, '0');
                const day = String(now.getDate()).padStart(2, '0');
                const hours = String(now.getHours()).padStart(2, '0');
                const minutes = String(now.getMinutes()).padStart(2, '0');
                const seconds = String(now.getSeconds()).padStart(2, '0');
                return `Facturas_${year}-${month}-${day}_${hours}:${minutes}:${seconds}.xlsx`;
            };

            const filename = generateFilename();

            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            alert('Error generando archivo Excel');
        }
    };

    const handleDateChange = (newValue, name) => {
        if (name === 'start') {
            setStartInvoiceDate(newValue);
        } else if (name === 'end') {
            setEndInvoiceDate(newValue);
        }
    };


    return (
        <div>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <>
                    {/* Filters and Sorting */}
                    <Grid container spacing={1} alignItems="center" sx={{ marginTop: '80px' }}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12} md={9}>
                                <Grid container spacing={1} alignItems="center">
                                    {/* Código */}
                                    <Grid item xs={3} sm={2}>
                                        <TextField
                                            fullWidth
                                            label="Código"
                                            name="code"
                                            value={code || ""}
                                            onChange={(e) => setCode(e.target.value)}
                                        />
                                    </Grid>
                                    {/* Código Externo */}
                                    <Grid item xs={3} sm={2}>
                                        <TextField
                                            fullWidth
                                            label="Código externo"
                                            name="external_provider_id"
                                            value={externalProviderId || ""}
                                            onChange={(e) => setExternalProviderId(e.target.value)}
                                        />
                                    </Grid>
                                    {/* Obra */}
                                    <Grid item sm={2}>
                                        <Autocomplete
                                            options={buildings}
                                            getOptionLabel={(option) => option.code?.toString() + '-' + option.name?.toString()}
                                            value={selectedBuilding}
                                            onChange={handleBuildingChange}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Obra" style={{ maxWidth: '100%' }} />
                                            )}
                                        />
                                    </Grid>
                                    {/* Cliente */}
                                    <Grid item sm={2}>
                                        <Autocomplete
                                            options={clients}
                                            getOptionLabel={(option) => option.code?.toString() + '-' + option.name?.toString()}
                                            value={selectedClient}
                                            onChange={handleClientChange}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Cliente" style={{ maxWidth: '100%' }} />
                                            )}
                                        />
                                    </Grid>
                                    {/* Proveedores */}
                                    <Grid item sm={2}>
                                        <Autocomplete
                                            options={providers}
                                            getOptionLabel={(option) => option.code?.toString() + '-' + option.name?.toString()}
                                            value={selectedProvider}
                                            onChange={handleProviderChange}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Proveedores" style={{ maxWidth: '100%' }} />
                                            )}
                                        />
                                    </Grid>
                                    {/* Estado */}
                                    <Grid item xs={3} sm={2}>
                                        <FormControl fullWidth>
                                            <InputLabel id="status-label">Estado</InputLabel>
                                            <Select
                                                labelId="status-label"
                                                id="status"
                                                name="status"
                                                value={status}
                                                onChange={handleStatusChange}
                                                label="Tipo"
                                            >
                                                <MenuItem value="paid">PAGADA</MenuItem>
                                                <MenuItem value="not_paid">NO PAGADA</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Botones alineados a la derecha */}
                            <Grid item xs={12} md={3}>
                                <Grid container spacing={1} justifyContent="flex-end">
                                    <Grid item>
                                        <Button variant="contained" color="primary" onClick={handleApplyFilters}>
                                            Aplicar Filtros
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" color="secondary" onClick={handleResetFilters}>
                                            Resetear Filtros
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>


                        {/* Filtros de Fecha */}
                        <Grid container spacing={1} alignItems="center" sx={{ marginTop: '10px' }}>
                            <Grid item xs={12} md={9}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                            <DesktopDatePicker
                                                inputFormat="DD/MM/YYYY"
                                                label="Fecha de Inicio"
                                                closeOnSelect={true}
                                                value={startInvoiceDate}
                                                onChange={(newValue) => handleDateChange(newValue, 'start')}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                            <DesktopDatePicker
                                                inputFormat="DD/MM/YYYY"
                                                label="Fecha de Fin"
                                                value={endInvoiceDate}
                                                onChange={(newValue) => handleDateChange(newValue, 'end')}
                                                closeOnSelect={true}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Export Button */}
                    <Grid item xs={12} sm={2} style={{ textAlign: 'right' }}>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={exportAllInvoices}
                        >
                            Exportar
                        </Button>
                    </Grid>
                    <br />
                    <br />
                    {/* Client list */}
                    <InvoiceWithTotalsTable
                        title={'Facturas de clientes'}
                        isClient={true}
                        code={code}
                        status={status}
                        selectedBuilding={selectedBuilding}
                        externalProviderId={externalProviderId}
                        selectedClient={selectedClient}
                        startInvoiceDate={startInvoiceDate}
                        endInvoiceDate={endInvoiceDate}
                        apply={apply}
                        reset={reset}
                    />
                    <br />
                    <br />
                    <br />
                    {/* Client list */}
                    <InvoiceWithTotalsTable
                        title={'Facturas de proveedores'}
                        isClient={false}
                        code={code}
                        status={status}
                        selectedBuilding={selectedBuilding}
                        externalProviderId={externalProviderId}
                        selectedProvider={selectedProvider}
                        startInvoiceDate={startInvoiceDate}
                        endInvoiceDate={endInvoiceDate}
                        apply={apply}
                        reset={reset}
                    />
                </>
            )
            }
            <br />
            {
                error && (
                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                        {error}
                    </Typography>
                )
            }
        </div >
    );
}

export default ListInvoices;
