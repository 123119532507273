import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../login/OAuth';
import {
    Button,
    TextField,
    Grid,
    Box,
    Paper,
    Typography,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@mui/material";

function NewProvider() {
    const { api } = useAuth();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [providerForm, setProviderForm] = useState({
        email: null,
        name: null,
        address: null,
        doc_type: null,
        documentation: null,
        country: null,
        cp: null,
        town: null,
        commercial_name: null,
        province: null,
        telephone_number: null,
        secondary_telephone_number: null,
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setProviderForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validateForm = () => {
        if (!providerForm.name) {
            return "Por favor, añada un nombre";
        }
        if (providerForm.email && providerForm.email.length > 36) {
            return "El email no debe exceder los 36 caracteres";
        }
        if (providerForm.name.length > 55) {
            return "El nombre no debe exceder los 55 caracteres";
        }
        if (providerForm.country && providerForm.country.length > 36) {
            return "El pais no debe exceder los 36 caracteres";
        }
        if (providerForm.cp && providerForm.cp.length > 9) {
            return "El codigo postal no debe exceder los 9 caracteres";
        }
        if (providerForm.town && providerForm.town.length > 150) {
            return "La comunidad autonoma no debe exceder los 150 caracteres";
        }
        if (providerForm.province && providerForm.province.length > 150) {
            return "La provincia no debe exceder los 150 caracteres";
        }
        if (providerForm.surname && providerForm.surname.length > 36) {
            return "Los apellidos no deben exceder los 36 caracteres";
        }
        if ((providerForm.doc_type && !providerForm.documentation) || (!providerForm.doc_type && providerForm.documentation)) {
            return "Si quiere poner una documentación, debe rellenar ambos campos (tipo y documentación).";
        }
        if (providerForm.documentation && providerForm.documentation.length > 15) {
            return "La documentación no debe exceder los 15 caracteres.";
        }
        if (providerForm.address && providerForm.address.length > 150) {
            return "La dirección no debe exceder los 150 caracteres";
        }
        if (providerForm.telephone_number && providerForm.telephone_number.length > 15) {
            return "El teléfono no debe exceder los 15 caracteres";
        }
        if (providerForm.secondary_telephone_number && providerForm.secondary_telephone_number.length > 15) {
            return "El teléfono secundario no debe exceder los 15 caracteres";
        }
        if (providerForm.commercial_name && providerForm.commercial_name.length > 36) {
            return "El alias no debe exceder los 36 caracteres";
        }
        return null;
    };

    const createProvider = async (event) => {
        event.preventDefault();
        const validationError = validateForm();
        if (validationError) {
            setError(validationError);
            return;
        }
        try {
            setLoading(true);

            const result = await api().post('/providers/new', providerForm);

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    alert("Proveedor creado correctamente");
                    navigate(`/providers/list`);
                    setError(null);
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '120%' }}>
            <Paper elevation={3} style={{ padding: 20, width: '80%' }}>
                <Typography component="h2" variant="h5">
                    Nuevo Proveedor
                </Typography>
                <br></br>
                <form onSubmit={createProvider}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{ marginTop: "20px" }}>
                            <Typography variant="h6">Información Principal</Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="Nombre"
                                name="name"
                                value={providerForm.name}
                                onChange={handleChange}
                                inputProps={{ maxLength: 55 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="Email"
                                name="email"
                                value={providerForm.email}
                                onChange={handleChange}
                                inputProps={{ maxLength: 36 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="Teléfono"
                                name="telephone_number"
                                value={providerForm.telephone_number}
                                onChange={handleChange}
                                inputProps={{ maxLength: 15 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="Teléfono secundario"
                                name="secondary_telephone_number"
                                value={providerForm.secondary_telephone_number}
                                onChange={handleChange}
                                inputProps={{ maxLength: 15 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="Nombre comercial (alias)"
                                name="commercial_name"
                                value={providerForm.commercial_name}
                                onChange={handleChange}
                                inputProps={{ maxLength: 36 }}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "20px" }}>
                            <Typography variant="h6">Documentación</Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControl
                                style={{ width: "100%" }}
                            >
                                <InputLabel id="document-type-label">
                                    Documentación
                                </InputLabel>
                                <Select
                                    labelId="document-type-label"
                                    id="document-type"
                                    label='Tipo documentación'
                                    value={providerForm.doc_type}
                                    onChange={handleChange}
                                    name="doc_type"
                                    inputProps={{ id: 'document-type' }}
                                >
                                    <MenuItem value="DNI">DNI</MenuItem>
                                    <MenuItem value="NIF">NIF</MenuItem>
                                    <MenuItem value="NIE">NIE</MenuItem>
                                    <MenuItem value="CIF">CIF</MenuItem>
                                    <MenuItem value="DOC_EXTRANJERA">DOC EXTRANJERA</MenuItem>
                                    <MenuItem value="OTRO">OTRO</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="Documentación"
                                name="documentation"
                                value={providerForm.documentation}
                                onChange={handleChange}
                                inputProps={{ maxLength: 15 }}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "20px" }}>
                            <Typography variant="h6">Dirección</Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="País"
                                name="country"
                                value={providerForm.country}
                                onChange={handleChange}
                                inputProps={{ maxLength: 36 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="Población"
                                name="town"
                                value={providerForm.town}
                                onChange={handleChange}
                                inputProps={{ maxLength: 150 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="Provincia"
                                name="province"
                                value={providerForm.province}
                                onChange={handleChange}
                                inputProps={{ maxLength: 150 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="Código postal"
                                name="cp"
                                value={providerForm.cp}
                                onChange={handleChange}
                                inputProps={{ maxLength: 9 }}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                label="Dirección"
                                name="address"
                                value={providerForm.address}
                                onChange={handleChange}
                                inputProps={{ maxLength: 150 }}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <Button type="submit" variant="contained" color="primary" disabled={loading}>
                                {loading ? <CircularProgress size={24} /> : 'Crear'}
                            </Button>
                        </Box>
                    </Grid>
                </form>
                {error && (
                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                        {error}
                    </Typography>
                )}
            </Paper >
        </div>
    );
}

export default NewProvider;
