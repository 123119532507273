import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import CreatePdf from '../../components/CreatePdf';
import UploadDocument from '../../components/UploadDocument';
import { DecimalTextField, formatDate, DatePickerComponent, formatPrice, DisableDialog } from '../../components/Utils';
import {
    CircularProgress,
    Typography,
    TextField,
    Button,
    Card,
    CardContent,
    CardHeader,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    Grid,
    TableContainer,
    Table,
    Paper,
    Divider
} from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

function EditInvoice() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const { api } = useAuth();
    const [error, setError] = useState(null);
    const [invoiceData, setInvoiceData] = useState(null);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [retentionPayment, setRetentionPayment] = useState(0);
    const [invoiceLimitDate, setInvoiceLimitDate] = useState(null);
    const [retentionLimitDate, setRetentionLimitDate] = useState(null);
    const [externalProviderId, setExternalProviderId] = useState(null);
    const [invoiceDate, setInvoiceDate] = useState(null);

    const [openDisableDialog, setOpenDisableDialog] = useState(false);
    const [disableId, setDisableId] = useState(null);
    const [navigateToWhenDisabled, setNavigateToWhenDisabled] = useState(false);
    const [endpointToDisable, setEndpointToDisable] = useState(false);

    const fetchInvoice = useCallback(async () => {
        try {
            const result = await api().get(`/invoices/${id}`);
            const invoice = result.data;
            setInvoiceData(invoice);
            setInvoiceLimitDate(invoice.invoice_limit_date);
            setRetentionLimitDate(invoice.retention_limit_date);
            setInvoiceDate(invoice.invoice_date);
            setExternalProviderId(invoice.external_provider_id);
            setLoading(false);
        } catch (error) {
            setError("Error obteniendo factura");
            setLoading(false);
        }
    }, [api, id]);

    useEffect(() => {
        fetchInvoice();
    }, []);

    const handlePayment = async (isRetentionPayment = false) => {
        try {
            setLoading(true);
            const endpoint = isRetentionPayment ? '/invoices/pay_retention' : '/invoices/pay_invoice';
            const amount = isRetentionPayment ? retentionPayment : paymentAmount;
            const updatedData = {
                invoice_id: id,
                amount: amount,
            };
            const result = await api().patch(endpoint, updatedData);
            if (result.status < 300) {
                alert(`Pago de ${amount} € realizado con éxito.`);
                if (isRetentionPayment) {
                    setRetentionPayment(0);
                } else {
                    setPaymentAmount(0);
                }
                fetchInvoice();
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleInvoiceUpdate = async (name, newValue) => {
        try {
            setLoading(true);
            let updatedData = null;
            if (name === 'external_provider_id') {
                updatedData = {
                    invoice_id: id,
                    [name]: newValue,
                };
            } else {
                const formattedDate = dayjs(newValue).hour(12).minute(0).second(0).utc().format('YYYY-MM-DD');
                if (name === 'retention_limit_date') {
                    setRetentionLimitDate(formattedDate);
                } else if (name === 'invoice_limit_date') {
                    setInvoiceLimitDate(formattedDate);
                } else if (name === 'invoice_date') {
                    setInvoiceDate(invoiceDate);
                }
                updatedData = {
                    invoice_id: id,
                    [name]: formattedDate,
                };
            }
            const result = await api().patch(`/invoices/`, updatedData);
            if (result.status < 300) {
                fetchInvoice();
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleDownloadDocument = async (documentId, documentName) => {
        try {
            const response = await api().get(`/documents/${documentId}`, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', documentName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            alert(`Error al descargar el documento: ${JSON.stringify(error.response.data.detail)}`);
        }
    };

    const handleOpenDisableDialog = (itemId, endpoint, navigateTo) => {
        setDisableId(itemId);
        setEndpointToDisable(endpoint);
        setNavigateToWhenDisabled(navigateTo);
        setOpenDisableDialog(true);
    };

    const invoiceBase = parseFloat((invoiceData?.base_price || 0).toFixed(2));
    const retention = parseFloat((invoiceData?.retention || 0).toFixed(2));
    const total = parseFloat(((invoiceData?.total_price || 0) - retention).toFixed(2));
    const vat = parseFloat(((invoiceData?.total_price || 0) - invoiceBase).toFixed(2));
    const remainingAmountToPay = invoiceData ? parseFloat((total - invoiceData.paid_amount).toFixed(2)) : 0;
    const remainingRetentionToPay = invoiceData ? parseFloat((invoiceData.retention - invoiceData.paid_retention).toFixed(2)) : 0;

    const mdSize = invoiceData?.delivery_notes.length > 0 ? 4 : 6;

    return (
        <div style={{ padding: 20 }}>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            ) : (
                <Card sx={{ margin: 'auto' }}>
                    <CardHeader
                        title={(
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', padding: '20px 0' }}>
                                <span style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                                    {`Factura: ${invoiceData.code} | Fecha: ${formatDate(invoiceData.invoice_date) || '-'}`}
                                </span>

                                <Grid item xs={2}
                                    style={{
                                        position: 'absolute',
                                        right: '16px',
                                    }}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handleOpenDisableDialog(id, '/invoices/disable', '/invoices/list')}
                                        style={{ backgroundColor: 'red', marginRight: '16px' }}
                                    >
                                        Eliminar
                                    </Button>
                                </Grid>
                            </div>
                        )}
                        sx={{
                            backgroundColor: '#f5f5f5',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            padding: '16px 0'
                        }}
                    />
                    {/* Diálogo de confirmación de eliminación */}
                    <DisableDialog
                        open={openDisableDialog}
                        onClose={() => setOpenDisableDialog(false)}
                        endpoint={endpointToDisable}
                        disableId={disableId}
                        navigateTo={navigateToWhenDisabled}
                    />
                    <CardContent>
                        {/* Nueva tarjeta: Información del Cliente o Proveedor */}
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Paper elevation={3} sx={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px', marginBottom: '20px', textAlign: 'left' }}>
                                    <Typography variant="h6" sx={{ mb: 3, fontWeight: 'bold', fontSize: '16px' }}>
                                        Información del {invoiceData.client_id ? 'Cliente' : 'Proveedor'}
                                    </Typography>
                                    <Typography variant="body2" sx={{ fontSize: '14px', marginBottom: '10px' }}>
                                        {invoiceData.client_id ? (
                                            <>
                                                <strong>Código: </strong>
                                                <Link to={`/clients/update/${invoiceData.client.id}`}>
                                                    {invoiceData.client.code || '-'} <br />
                                                </Link>
                                                <strong>Nombre: </strong> {invoiceData.client.name || '-'} <br />
                                                <strong>Email: </strong> {invoiceData.client.email || '-'} <br />
                                                <strong>Teléfono: </strong> {invoiceData.client.telephone_number || '-'} <br />
                                                <strong>Documentación: </strong> {invoiceData.client.documentation || '-'} <br />
                                            </>
                                        ) : (
                                            <>
                                                <strong>Código: </strong>
                                                <Link to={`/providers/update/${invoiceData.provider.id}`}>
                                                    {invoiceData.provider.code || '-'} <br />
                                                </Link>
                                                <strong>Nombre: </strong> {invoiceData.provider.name || '-'} <br />
                                                <strong>Email: </strong> {invoiceData.provider.email || '-'} <br />
                                                <strong>Teléfono: </strong> {invoiceData.provider.telephone_number || '-'} <br />
                                                <strong>Documentación: </strong> {invoiceData.provider.documentation || '-'} <br />
                                            </>
                                        )}
                                    </Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                        {/* Contenedor con dos columnas */}
                        <Grid container spacing={3}>
                            {/* Columna izquierda: Información de la Factura */}
                            <Grid item xs={12} md={6}>
                                <Paper elevation={3} sx={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px', marginBottom: '20px', textAlign: 'left' }}>
                                    <Typography variant="h6" sx={{ mb: 3, fontWeight: 'bold', fontSize: '16px' }}>
                                        Información de la Factura
                                    </Typography>

                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12} md={12}>
                                            <Grid container spacing={2}>
                                                {/* Fecha de la factura */}
                                                <Grid item xs={12} md={mdSize}>
                                                    <Typography variant="body2" sx={{ fontSize: '14px', display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
                                                        Fecha de la factura:
                                                    </Typography>

                                                    <DatePickerComponent
                                                        label="Fecha factura"
                                                        value={invoiceDate ? invoiceDate : null}
                                                        onChange={(newValue) => handleInvoiceUpdate('invoice_date', newValue)}
                                                    />
                                                </Grid>

                                                {/* Fecha vencimiento de la factura */}
                                                <Grid item xs={12} md={mdSize}>
                                                    <Typography variant="body2" sx={{ fontSize: '14px', display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
                                                        Fecha vencimiento de la factura:
                                                    </Typography>

                                                    <DatePickerComponent
                                                        label="Fecha vencimiento"
                                                        value={invoiceLimitDate ? invoiceLimitDate : null}
                                                        onChange={(newValue) => handleInvoiceUpdate('invoice_limit_date', newValue)}
                                                    />
                                                </Grid>
                                                {invoiceData?.delivery_notes.length > 0 ? (
                                                    <>
                                                        <Grid item xs={12} md={mdSize}>
                                                            <Typography variant="body2" sx={{ fontSize: '14px', display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
                                                                Código externo:
                                                            </Typography>
                                                            <TextField
                                                                fullWidth
                                                                label="Código externo"
                                                                name="external_provider_id"
                                                                value={externalProviderId}
                                                                onChange={(e) => setExternalProviderId(e.target.value)}
                                                                InputLabelProps={{ shrink: !!externalProviderId }}
                                                                inputProps={{ maxLength: 50 }}
                                                            />
                                                        </Grid>
                                                        
                                                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => handleInvoiceUpdate('external_provider_id', externalProviderId)}
                                                            >
                                                                Actualizar
                                                            </Button>
                                                        </Grid>
                                                    </>
                                                ) : (<></>)}
                                            </Grid>
                                        </Grid>

                                        {/* Total factura */}
                                        <Grid item xs={12} sm={4} sx={{ marginTop: '12px' }} >
                                            <Paper elevation={1} sx={{ padding: '10px', backgroundColor: '#ffffff', borderRadius: '8px' }}>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '14px' }}>Total factura</Typography>
                                                <Typography variant="h6" sx={{ fontSize: '16px' }}>
                                                    {formatPrice(total)}
                                                </Typography>
                                            </Paper>
                                        </Grid>

                                        {/* Pagado */}
                                        <Grid item xs={12} sm={4} sx={{ marginTop: '12px' }}>
                                            <Paper elevation={1} sx={{ padding: '10px', backgroundColor: '#e0ffe0', borderRadius: '8px' }}>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '14px' }}>Pagado</Typography>
                                                <Typography variant="h6" sx={{ fontSize: '16px' }}>
                                                    {formatPrice(invoiceData.paid_amount)}
                                                </Typography>
                                            </Paper>
                                        </Grid>

                                        {/* Pendiente */}
                                        <Grid item xs={12} sm={4} sx={{ marginTop: '12px' }}>
                                            <Paper elevation={1} sx={{ padding: '10px', backgroundColor: '#ffe0e0', borderRadius: '8px' }}>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '14px' }}>Pendiente</Typography>
                                                <Typography variant="h6" sx={{ fontSize: '16px' }}>
                                                    {formatPrice(remainingAmountToPay)}
                                                </Typography>
                                            </Paper>
                                        </Grid>

                                        {/* Pago */}
                                        <Grid item xs={12} md={8}>
                                            <DecimalTextField
                                                label="Monto a Pagar"
                                                value={paymentAmount}
                                                onChange={(e) => setPaymentAmount(e.target.value)}
                                                variant="outlined"
                                                fullWidth
                                                size="medium"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={paymentAmount <= 0 || paymentAmount > remainingAmountToPay}
                                                onClick={() => handlePayment(false)}
                                                sx={{ height: '100%', minWidth: '150px' }}
                                            >
                                                Pagar Factura
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                            {/* Columna derecha: Información de la Retención */}
                            <Grid item xs={12} md={6}>
                                <Paper elevation={3} sx={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px', marginBottom: '20px', textAlign: 'left' }}>
                                    <Typography variant="h6" sx={{ mb: 3, fontWeight: 'bold', fontSize: '16px' }}>
                                        Información de la Retención
                                    </Typography>

                                    <Grid container spacing={2} alignItems="center">
                                        {/* Fecha vencimiento de la retención */}
                                        <Grid item xs={12} md={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={12}>
                                                    <Typography variant="body2" sx={{ fontSize: '14px' }}>Fecha vencimiento de la retención:</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                <DatePickerComponent
                                                        label="Fecha vencimiento"
                                                        value={retentionLimitDate ? retentionLimitDate : null}
                                                        onChange={(newValue) => handleInvoiceUpdate('retention_limit_date', newValue)}
                                                    />
                                                </Grid>
                                                {invoiceData?.delivery_notes.length > 0 ? (
                                                    <Grid item xs={12} md={12} sx={{ marginTop: '28px' }}>
                                                    </Grid>
                                                ) : (<></>)}
                                            </Grid>
                                        </Grid>

                                        {/* Retención total */}
                                        <Grid item xs={12} sm={4} sx={{ marginTop: '10px' }}>
                                            <Paper elevation={1} sx={{ padding: '10px', backgroundColor: '#ffffff', borderRadius: '8px' }}>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '14px' }}>Total retención</Typography>
                                                <Typography variant="h6" sx={{ fontSize: '16px' }}>
                                                    {formatPrice(retention)}
                                                </Typography>
                                            </Paper>
                                        </Grid>

                                        {/* Retención pagada */}
                                        <Grid item xs={12} sm={4} sx={{ marginTop: '10px' }}>
                                            <Paper elevation={1} sx={{ padding: '10px', backgroundColor: '#e0ffe0', borderRadius: '8px' }}>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '14px' }}>Retención pagada</Typography>
                                                <Typography variant="h6" sx={{ fontSize: '16px' }}>
                                                    {formatPrice(invoiceData.paid_retention)}
                                                </Typography>
                                            </Paper>
                                        </Grid>

                                        {/* Pendiente retención */}
                                        <Grid item xs={12} sm={4} sx={{ marginTop: '10px' }}>
                                            <Paper elevation={1} sx={{ padding: '10px', backgroundColor: '#ffe0e0', borderRadius: '8px' }}>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '14px' }}>Pendiente retención</Typography>
                                                <Typography variant="h6" sx={{ fontSize: '16px' }}>
                                                    {formatPrice(remainingRetentionToPay)}
                                                </Typography>
                                            </Paper>
                                        </Grid>

                                        {/* Pago de retención */}
                                        <Grid item xs={12} md={8}>
                                            <DecimalTextField
                                                label="Monto a Pagar"
                                                type="number"
                                                value={retentionPayment}
                                                onChange={(e) => setRetentionPayment(e.target.value)}
                                                variant="outlined"
                                                fullWidth
                                                size="medium"
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={retentionPayment <= 0 || retentionPayment > remainingRetentionToPay}
                                                onClick={() => handlePayment(true)}
                                                sx={{ height: '100%', minWidth: '150px' }}
                                            >
                                                Pagar Retención
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                        {/* Tabla de la Obra */}
                        <Paper elevation={3} sx={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px', marginTop: '20px' }}>
                            <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>
                                Obra: {invoiceData.building?.name ? invoiceData.building.name : 'Sin obra'}
                            </Typography>

                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><strong>{invoiceData.certification ? 'Certificación' : 'Albarán'}</strong></TableCell>
                                            <TableCell><strong>{invoiceData.certification ? 'Partida' : 'Artículo'}</strong></TableCell>
                                            <TableCell><strong>{invoiceData.certification ? 'Porcentaje' : 'Precio Unitario'}</strong></TableCell>
                                            <TableCell><strong>{invoiceData.certification ? 'Precio' : 'Cantidad'}</strong></TableCell>
                                            <TableCell><strong>Total</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {invoiceData.certification ? (
                                            invoiceData.certification.item_progress.map((progress) => {
                                                const item = progress.item;
                                                const totalPrice = (item.total_price * progress.percentage) / 100;

                                                return (
                                                    <TableRow key={progress.id}>
                                                        <TableCell>
                                                            <Link to={`/certifications/update/${invoiceData.certification.id}`}>
                                                                {invoiceData.certification.code}
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell>{item.name}</TableCell>
                                                        <TableCell>{progress.percentage}%</TableCell>
                                                        <TableCell>{formatPrice(item.total_price)}</TableCell>
                                                        <TableCell>{formatPrice(totalPrice)}</TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        ) : (
                                            invoiceData.delivery_notes.map((note) =>
                                                note.delivery_note_product_orders.map((order) => {
                                                    const totalPrice = order.price * order.amount;

                                                    return (
                                                        <TableRow key={order.id}>
                                                            <TableCell>
                                                                <Link to={`/delivery_notes/update/${note.id}`}>
                                                                    {note.code}
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell>{order.product_order.product_name}</TableCell>
                                                            <TableCell>{formatPrice(order.price)}</TableCell>
                                                            <TableCell>{order.amount}</TableCell>
                                                            <TableCell>{formatPrice(totalPrice)}</TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            )
                                        )}

                                        {/* Información Adicional */}
                                        <TableRow>
                                            <TableCell>RETENCIÓN</TableCell>
                                            <TableCell>-</TableCell>
                                            <TableCell>-</TableCell>
                                            <TableCell>-</TableCell>
                                            <TableCell>{formatPrice(retention)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell rowSpan={4} colSpan={3}></TableCell>
                                            <TableCell><strong>Base imponible</strong></TableCell>
                                            <TableCell>{formatPrice(invoiceBase)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><strong>Iva ({invoiceData?.vat || 0}%)</strong></TableCell>
                                            <TableCell>{formatPrice(vat)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><strong>Total</strong></TableCell>
                                            <TableCell><strong>{formatPrice(total)}</strong></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        {/* Información adicional */}
                        <CardContent>
                            <Divider sx={{ my: 2 }} />
                            <div style={{ textAlign: 'right' }}>

                                {/* Agregar los botones según el tipo de factura */}
                                {invoiceData.certification ? (
                                    <>
                                        <CreatePdf
                                            endpoint={`/invoices/${invoiceData.id}/generate-pdf/proform`}
                                            text="Ver proforma"
                                        />
                                        <CreatePdf
                                            endpoint={`/invoices/${invoiceData.id}/generate-pdf/invoice`}
                                        />
                                    </>
                                ) : invoiceData.delivery_notes.length > 0 ? (
                                    <>
                                        {invoiceData.documents && invoiceData.documents.length > 0 && (() => {
                                            const activeDocument = invoiceData.documents.find(doc => !doc.disabled);
                                            return activeDocument ? (
                                                <Button
                                                    size="small"
                                                    onClick={() =>
                                                        handleDownloadDocument(activeDocument.id, activeDocument.name)
                                                    }
                                                    sx={{ marginRight: 2 }}
                                                >
                                                    {activeDocument.name}
                                                </Button>
                                            ) : null;
                                        })()}
                                        <UploadDocument
                                            to={'invoice_id'}
                                            id={invoiceData.id}
                                            fetch={fetchInvoice}
                                            showDisableDoc={invoiceData.documents && invoiceData.documents.length}
                                        />
                                    </>
                                ) : null}
                            </div>
                        </CardContent>
                    </CardContent>
                </Card>
            )
            }
        </div >
    );
}

export default EditInvoice;
