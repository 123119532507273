import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { Add as AddIcon } from '@mui/icons-material';

import {
    TextField,
    Button,
    Typography,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    CircularProgress,
    Paper,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import Accordion from '../../components/Accordion';
import InvoiceRetentionTable from '../../components/InvoiceRetentionTable';
import CreatePdf from '../../components/CreatePdf';
import { formatDate } from '../../components/Utils';

function EditProvider() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const { api } = useAuth();
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const [orders, setOrders] = useState([]);

    const [documents, setDocuments] = useState([]);
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadError, setUploadError] = useState(null);

    const [invoices, setInvoices] = useState([]);
    const [totalPriceSum, setTotalPriceSum] = useState(null);
    const [paidAmountSum, setPaidAmountSum] = useState(null);
    const [remainingAmountSum, setRemainingAmountSum] = useState(null);
    const [totalRetention, setTotalRetention] = useState(null);
    const [paidRetention, setPaidRetention] = useState(null);
    const [remainingRetentionSum, setRemainingRetentionSum] = useState(null);

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteId, setDeleteId] = useState(false);
    const [typeDelete, setTypeDelete] = useState(false);

    const [providerForm, setProviderForm] = useState({});

    const fetchProvider = useCallback(async () => {
        try {
            const result = await api().get(`/providers/${id}`);
            const provider = result.data.provider
            setProviderForm(provider);
            setProviderForm(prevState => ({ ...prevState, provider_id: id }));
            setInvoices(provider.invoices);
            setTotalPriceSum(result.data.total_price_sum);
            setPaidAmountSum(result.data.paid_amount_sum);
            setRemainingAmountSum(result.data.remaining_amount_sum);
            setTotalRetention(result.data.total_retentions);
            setPaidRetention(result.data.paid_retention);
            setRemainingRetentionSum(result.data.remaining_retention_sum);
            setOrders(provider.orders);
            setLoading(false);
        } catch (error) {
            setError("Error obteniendo proveedor");
            setLoading(false);
        }
    }, [api, id]);

    const fetchDocuments = useCallback(async () => {
        try {
            const params = { provider_id: id };
            const result = await api().get(`/documents/`, { params });
            setDocuments(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError("Error obteniendo documentos");
            setLoading(false);
        }
    }, [api, id]);

    useEffect(() => {
        fetchProvider();
        fetchDocuments();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProviderForm({ ...providerForm, [name]: value });
    };

    const validateForm = () => {
        if (!providerForm.name) {
            return "Por favor, añada un nombre";
        }
        if (providerForm.email && providerForm.email.length > 36) {
            return "El email no debe exceder los 36 caracteres";
        }
        if (providerForm.name.length > 55) {
            return "El nombre no debe exceder los 55 caracteres";
        }
        if (providerForm.country && providerForm.country.length > 36) {
            return "El pais no debe exceder los 36 caracteres";
        }
        if (providerForm.cp && providerForm.cp.length > 9) {
            return "El codigo postal no debe exceder los 9 caracteres";
        }
        if (providerForm.town && providerForm.town.length > 150) {
            return "La comunidad autonoma no debe exceder los 150 caracteres";
        }
        if (providerForm.province && providerForm.province.length > 150) {
            return "La provincia no debe exceder los 150 caracteres";
        }
        if (providerForm.surname && providerForm.surname.length > 36) {
            return "Los apellidos no deben exceder los 36 caracteres";
        }
        if ((providerForm.doc_type && !providerForm.documentation) || (!providerForm.doc_type && providerForm.documentation)) {
            return "Si quiere poner una documentación, debe rellenar ambos campos (tipo y documentación).";
        }
        if (providerForm.documentation && providerForm.documentation.length > 15) {
            return "La documentación no debe exceder los 15 caracteres.";
        }
        if (providerForm.address && providerForm.address.length > 150) {
            return "La dirección no debe exceder los 150 caracteres";
        }
        if (providerForm.telephone_number && providerForm.telephone_number.length > 15) {
            return "El teléfono no debe exceder los 15 caracteres";
        }
        if (providerForm.secondary_telephone_number && providerForm.secondary_telephone_number.length > 15) {
            return "El teléfono secundario no debe exceder los 15 caracteres";
        }
        if (providerForm.commercial_name && providerForm.commercial_name.length > 36) {
            return "El alias no debe exceder los 36 caracteres";
        }
        return null;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const validationError = validateForm();
            if (validationError) {
                setError(validationError);
                return;
            }
            setLoading(true);
            const result = await api().patch(`/providers/update`, providerForm);
            if (result.status < 300) {
                alert('Proveedor actualizado correctamente');
                fetchProvider()
                setError(null);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        } finally {
            setLoading(false);
        }
    };
    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };
    const handleUpload = async () => {
        if (!selectedFile) {
            setUploadError("Por favor selecciona un archivo");
            return;
        }
        const formData = new FormData();
        formData.append('document', selectedFile);
        formData.append('provider_id', id);

        try {
            setLoading(true);
            const result = await api().post(`/documents/add`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            if (result.status < 300) {
                alert('Documento subido correctamente');
                fetchDocuments();
                setUploadModalOpen(false);
                setSelectedFile(null);
                setUploadError(null);
            } else {
                setUploadError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setUploadError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        } finally {
            setLoading(false);
        }
    };
    const handleDownloadDocument = async (documentId, documentName) => {
        try {
            const response = await api().get(`/documents/${documentId}`, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', documentName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            setError(`Error al descargar el documento: ${JSON.stringify(error.response.data.detail)}`);
        }
    };
    const cellStyle = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '75px'
    };

    const handleOpenDeleteDialog = (itemId, type) => {
        setDeleteId(itemId);
        setTypeDelete(type);
        setOpenDeleteDialog(true);
    };
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };
    const handleDeleteItem = async () => {
        try {
            let data = {}
            if (typeDelete === '/certifications/disable') data.certification_id = deleteId
            if (typeDelete === '/orders/disable') data.order_id = deleteId
            if (typeDelete === '/invoices/disable') data.id = deleteId
            if (typeDelete === '/providers/disable') data.provider_id = deleteId
            if (typeDelete === '/documents/disable') data.document_id = deleteId

            const result = await api().patch(`${typeDelete}`, data);

            if (result.status < 300) {
                alert('Eliminado correctamente');
                if (typeDelete === '/providers/disable') {
                    navigate(`/providers/list`);
                } else if (typeDelete === '/orders/disable') {
                    fetchProvider();
                } else if (typeDelete === '/documents/disable') {
                    fetchDocuments();
                } else {
                    fetchProvider();
                }
                setOpenDeleteDialog(false);
            } else {
                alert(`Error ${result.status}: ${result.data.detail}`)

            }
        } catch (error) {
            alert(`Error: ${error.response?.data?.detail || error.message}`)
        };
    };
    return (
        <div style={{ padding: 20 }}>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <div>
                    <Grid container spacing={3} style={{ position: 'fixed', top: 60, right: 10, zIndex: 1100, width: 'auto' }}>
                        <Grid item>
                            <Paper elevation={0} style={{ padding: '10px', textAlign: 'center', backgroundColor: '#fff' }}>
                                <Box mb={2}>
                                    <Typography variant="h5" component="h1" gutterBottom>
                                        {providerForm.code} - {providerForm.name}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={10} lg={8}>
                        <Accordion title={`${providerForm.code} ${providerForm.name}`}>
                            <form onSubmit={handleSubmit} style={{ padding: '20px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} style={{ marginTop: "20px" }}>
                                        <Typography variant="h6">Información Principal</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Nombre"
                                            name="name"
                                            value={providerForm.name}
                                            onChange={handleChange}
                                            margin="normal"
                                            InputLabelProps={{ shrink: !!providerForm.name }}
                                            inputProps={{ maxLength: 55 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Email"
                                            name="email"
                                            value={providerForm.email}
                                            onChange={handleChange}
                                            margin="normal"
                                            InputLabelProps={{ shrink: !!providerForm.email }}
                                            inputProps={{ maxLength: 36 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Teléfono"
                                            name="telephone_number"
                                            value={providerForm.telephone_number}
                                            onChange={handleChange}
                                            margin="normal"
                                            InputLabelProps={{ shrink: !!providerForm.telephone_number }}
                                            inputProps={{ maxLength: 15 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Teléfono secundario"
                                            name="secondary_telephone_number"
                                            value={providerForm.secondary_telephone_number}
                                            onChange={handleChange}
                                            margin="normal"
                                            InputLabelProps={{ shrink: !!providerForm.secondary_telephone_number }}
                                            inputProps={{ maxLength: 15 }}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Nombre comercial (alias)"
                                            name="commercial_name"
                                            value={providerForm.commercial_name}
                                            onChange={handleChange}
                                            margin="normal"
                                            InputLabelProps={{ shrink: !!providerForm.commercial_name }}
                                            inputProps={{ maxLength: 36 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ marginTop: "20px" }}>
                                        <Typography variant="h6">Documentación</Typography>
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <FormControl
                                            style={{ width: "100%" }}
                                        >
                                            <InputLabel id="document-type-label">
                                                Documentación
                                            </InputLabel>
                                            <Select
                                                labelId="document-type-label"
                                                id="document-type"
                                                label='Tipo documentación'
                                                value={providerForm.doc_type}
                                                onChange={handleChange}
                                                name="doc_type"
                                                inputProps={{ id: 'document-type' }}
                                            >
                                                <MenuItem value="DNI">DNI</MenuItem>
                                                <MenuItem value="NIF">NIF</MenuItem>
                                                <MenuItem value="NIE">NIE</MenuItem>
                                                <MenuItem value="CIF">CIF</MenuItem>
                                                <MenuItem value="DOC_EXTRANJERA">DOC EXTRANJERA</MenuItem>
                                                <MenuItem value="OTRO">OTRO</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Documentación"
                                            name="documentation"
                                            value={providerForm.documentation}
                                            onChange={handleChange}
                                            inputProps={{ maxLength: 15 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ marginTop: "20px" }}>
                                        <Typography variant="h6">Documentación</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            fullWidth
                                            label="País"
                                            name="country"
                                            value={providerForm.country}
                                            onChange={handleChange}
                                            margin="normal"
                                            InputLabelProps={{ shrink: !!providerForm.country }}
                                            inputProps={{ maxLength: 36 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Población"
                                            name="town"
                                            value={providerForm.town}
                                            onChange={handleChange}
                                            margin="normal"
                                            InputLabelProps={{ shrink: !!providerForm.town }}
                                            inputProps={{ maxLength: 150 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Provincia"
                                            name="province"
                                            value={providerForm.province}
                                            onChange={handleChange}
                                            margin="normal"
                                            InputLabelProps={{ shrink: !!providerForm.province }}
                                            inputProps={{ maxLength: 150 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Código postal"
                                            name="cp"
                                            value={providerForm.cp}
                                            onChange={handleChange}
                                            margin="normal"
                                            InputLabelProps={{ shrink: !!providerForm.cp }}
                                            inputProps={{ maxLength: 9 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Dirección"
                                            name="address"
                                            value={providerForm.address}
                                            onChange={handleChange}
                                            margin="normal"
                                            InputLabelProps={{ shrink: !!providerForm.address }}
                                            inputProps={{ maxLength: 150 }}
                                        />
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleOpenDeleteDialog(id, '/providers/disable')}
                                            fullWidth
                                            style={{ backgroundColor: 'red' }}>
                                            Eliminar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button type="submit" variant="contained" color="primary" disabled={loading} fullWidth>
                                            Actualizar
                                        </Button>
                                    </Grid>
                                </Grid>
                                {error && (
                                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                        {error}
                                    </Typography>
                                )}
                            </form>
                        </Accordion>
                        <Accordion title="Documentos">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setUploadModalOpen(true)}
                                startIcon={<AddIcon />}
                                style={{ marginTop: 10 }}
                            >
                                Subir documento
                            </Button>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nombre</TableCell>
                                            <TableCell>Acciones</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {documents.filter(item => !item.disabled).map(item => (
                                            <TableRow key={item.id}>
                                                <TableCell>
                                                    <Button onClick={() => handleDownloadDocument(item.id, item.name)}>
                                                        {item.name}
                                                    </Button>
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="outlined"
                                                        color="secondary"
                                                        onClick={() => handleOpenDeleteDialog(item.id, '/documents/disable')}
                                                    >
                                                        Eliminar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Dialog open={uploadModalOpen} onClose={() => setUploadModalOpen(false)}>
                                <DialogTitle>Subir documento (pdf, word, excel o imagen hasta 20mb)</DialogTitle>
                                <DialogContent>
                                    <input type="file" onChange={handleFileChange} />
                                    {uploadError && (
                                        <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                            {uploadError}
                                        </Typography>
                                    )}
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setUploadModalOpen(false)} color="primary">
                                        Cancelar
                                    </Button>
                                    <Button onClick={handleUpload} color="primary" variant='contained'>
                                        Enviar
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Accordion>
                        <Accordion title='Pedidos'>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <Typography>Código</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>Obra</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>Estado</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>Fecha</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>Precio total</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>Acciones</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {orders.filter(item => !item.disabled).map(item => (
                                            <TableRow key={item.id}>
                                                <TableCell component="th" scope="row" sx={cellStyle}>
                                                    <Link to={`/orders/update/${item.id}`}>
                                                        {item.code || '-'}
                                                    </Link>
                                                </TableCell>
                                                <TableCell component="th" scope="row" sx={cellStyle}>
                                                    {item.building ? (
                                                        <Link to={`/buildings/update/${item.building.id}`}>
                                                            {item.building.code || '-'}
                                                        </Link>
                                                    ) : (
                                                        '-'
                                                    )}
                                                </TableCell>
                                                <TableCell component="th" scope="row" sx={cellStyle}>
                                                    {item.order_status === 'partle_accepted' ? 'PARCIALMENTE ACEPTADO' :
                                                        item.order_status === 'received' ? 'RECIBIDO' :
                                                            item.order_status === 'pending' ? 'PENDIENTE' :
                                                                item.order_status || '-'}
                                                </TableCell>
                                                <TableCell sx={cellStyle}>
                                                    {formatDate(item.order_date) || '-'}
                                                </TableCell>
                                                <TableCell sx={cellStyle}>
                                                    {item.total_price ? item.total_price.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : '0,00€'}
                                                </TableCell>
                                                <TableCell>
                                                    <CreatePdf endpoint={`/orders/${item.id}/generate-pdf`}></CreatePdf>
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        color="secondary"
                                                        onClick={() => handleOpenDeleteDialog(item.id, '/orders/disable')}
                                                        sx={{ ml: 2 }}
                                                    >
                                                        Borrar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Accordion>
                        <InvoiceRetentionTable
                            isInvoice={true}
                            isClient={false}
                            showBuilding={true}
                            uploadDocument={true}
                            showClientOrProvider={false}
                            title='Facturas'
                            totalAmount={totalPriceSum}
                            paidAmount={paidAmountSum}
                            remainingAmountSum={remainingAmountSum}
                            invoices={invoices}
                            fetch={fetchProvider}
                            handleOpenDeleteDialog={handleOpenDeleteDialog}
                        />
                        <InvoiceRetentionTable
                            isInvoice={false}
                            isClient={false}
                            showBuilding={true}
                            showClientOrProvider={false}
                            title='Retenciones'
                            totalAmount={totalRetention}
                            paidAmount={paidRetention}
                            remainingAmountSum={remainingRetentionSum}
                            invoices={invoices}
                            fetch={fetchProvider}
                            handleOpenDeleteDialog={handleOpenDeleteDialog}
                        />

                        <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                            <DialogTitle>¿Estás seguro?</DialogTitle>
                            <DialogActions>
                                <Button onClick={handleCloseDeleteDialog} color="primary">
                                    Volver
                                </Button>
                                <Button onClick={handleDeleteItem} color="error">
                                    Eliminar
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </div>)}
        </div>

    );
}

export default EditProvider;
