import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../login/OAuth';
import {
    Button,
    TextField,
    Grid,
    Autocomplete,
    Box,
    Paper,
    Typography,
    CircularProgress,
    IconButton,
    MenuItem,
    Select,
    FormHelperText,
    InputLabel,
    FormControl,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import "dayjs/locale/es";
import utc from 'dayjs/plugin/utc';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

dayjs.extend(utc);

function NewOffer() {
    const { api } = useAuth();
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);

    const [budget, setBudget] = useState(0);

    const [offerForm, setOfferForm] = useState({
        name: '',
        start_date: null,
        end_date: null,
        description: '',
        client_id: '',
    });

    const [parts, setParts] = useState([{ name: '', description: '', unit_price: '', quantity: '', unit: '' }]);

    const fetchClients = useCallback(async () => {
        try {
            setLoading(true);

            const result = await api().get("/clients/");

            setClients(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError({ global: `Error: ${JSON.stringify(error.response.data.detail)}` });
        }
    }, [api]);

    useEffect(() => {
        fetchClients();
    }, []);

    useEffect(() => {
        const calculatedBudget = parts.reduce((total, part) => {
            const unit_price = parseFloat(part.unit_price) || 0;
            const quantity = parseFloat(part.quantity) || 0;
            return total + (unit_price * quantity);
        }, 0);

        setBudget(calculatedBudget.toFixed(2));
    }, [parts]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setOfferForm(prevState => ({
            ...prevState,
            [name]: value
        }));
        // Limpiar error del campo si se corrige
        setError(prevError => ({
            ...prevError,
            [name]: ''
        }));
    };

    const handleDateChange = (name, newValue) => {
        let date = dayjs(newValue).hour(12).minute(0).second(0);
        date = date.utc().format('YYYY-MM-DD');
        setOfferForm(prevState => ({
            ...prevState,
            [name]: date
        }));
        // Limpiar error de fecha si se corrige
        setError(prevError => ({
            ...prevError,
            [name]: ''
        }));
    };

    const handleClientChange = (event, newValue) => {
        setSelectedClient(newValue);
        setOfferForm(prevState => ({
            ...prevState,
            client_id: newValue ? newValue.id : ''
        }));
        // Limpiar error del cliente si se corrige
        setError(prevError => ({
            ...prevError,
            client_id: ''
        }));
    };

    const handlePartsChange = (index, field, value) => {
        const newParts = [...parts];
        newParts[index][field] = value;
        setParts(newParts);
        // Limpiar error del campo específico de la parte si se corrige
        setError(prevError => ({
            ...prevError,
            [`part_${index}_${field}`]: ''
        }));
    };

    const addPart = () => {
        setParts([...parts, { name: '', description: '', unit_price: '', quantity: '', unit: '' }]);
    };

    const removePart = (index) => {
        const newParts = parts.filter((_, i) => i !== index);
        setParts(newParts);
    };

    const validateForm = () => {
        const newError = {};

        if (!offerForm.name) {
            newError.name = "Por favor, añada un nombre";
        } else if (offerForm.name.length > 36) {
            newError.name = "El nombre no debe exceder los 36 caracteres";
        }

        if (!offerForm.client_id) {
            newError.client_id = "Por favor, seleccione un cliente";
        }

        parts.forEach((part, index) => {
            if (!part.name) {
                newError[`part_${index}_name`] = "El nombre de la parte es obligatorio";
            }
            if (!part.unit_price) {
                newError[`part_${index}_unit_price`] = "El precio unitario es obligatorio";
            } else {
                const unit_price = parseFloat(part.unit_price);
                if (unit_price > 99000000) {
                    newError[`part_${index}_unit_price`] = "El precio unitario no puede ser mayor a 99 millones.";
                } else if (!Number.isInteger(unit_price * 100)) {
                    newError[`part_${index}_unit_price`] = "El precio unitario solo puede tener hasta 2 decimales.";
                } else if (unit_price <= 0) {
                    newError[`part_${index}_quantity`] = "El precio unitario tiene que ser positivo.";
                }
            }
            if (!part.quantity) {
                newError[`part_${index}_quantity`] = "La cantidad es obligatoria";
            } else {
                const quantity = parseFloat(part.quantity);
                if (quantity > 99000000) {
                    newError[`part_${index}_quantity`] = "La cantidad no puede ser mayor a 99 millones.";
                } else if (!Number.isInteger(quantity * 100)) {
                    newError[`part_${index}_quantity`] = "La cantidad solo puede tener hasta 2 decimales.";
                } else if (quantity <= 0) {
                    newError[`part_${index}_quantity`] = "La cantidad tiene que ser positiva.";
                }
            }
            if (!part.unit) {
                newError[`part_${index}_unit`] = "La unidad es obligatoria";
            }
        });

        setError(newError);
        return Object.keys(newError).length === 0;
    };

    const createOffer = async (event) => {
        event.preventDefault();
        const isValid = validateForm();
        if (!isValid) {
            return;
        }
        try {
            setLoading(true);
            const validParts = parts.filter(part => part.name && part.name.trim() !== '');
            const result = await api().post('/offers/', { ...offerForm, items: validParts });

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    alert("Oferta creada correctamente");
                    navigate(`/offers/list`);
                    setError({});
                }
            } else {
                setError({ global: `Error ${result.status}: ${result.data.detail}` });
            }
        } catch (error) {
            setError({ global: `Error: ${JSON.stringify(error.response.data.detail)}` });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh', padding: '20px 0' }}>
            <Paper elevation={3} style={{ padding: 20, width: '80%' }}>
                <Typography component="h2" variant="h5">
                    Nueva Oferta
                </Typography>
                <br />
                <form onSubmit={createOffer}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                label="Nombre*"
                                name="name"
                                value={offerForm.name}
                                onChange={handleChange}
                                margin="normal"
                                inputProps={{ maxLength: 36 }}
                                error={!!error.name}
                                helperText={error.name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Autocomplete
                                options={clients}
                                getOptionLabel={(option) => option.name?.toString()}
                                value={selectedClient}
                                onChange={handleClientChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        margin="normal"
                                        label="Cliente*"
                                        style={{ maxWidth: '100%' }}
                                        error={!!error.client_id}
                                        helperText={error.client_id}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                fullWidth
                                label="Presupuesto"
                                value={budget.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€'}
                                margin="normal"
                                inputProps={{ readOnly: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                <DesktopDatePicker
                                    inputFormat="DD/MM/YYYY"
                                    label="Fecha de inicio"
                                    value={offerForm.start_date ? dayjs(offerForm.start_date) : null}
                                    onChange={(newValue) => handleDateChange('start_date', newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            margin="normal"
                                            error={!!error.start_date}
                                            helperText={error.start_date}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                <DesktopDatePicker
                                    label="Fecha de fin"
                                    inputFormat="DD/MM/YYYY"
                                    value={offerForm.end_date ? dayjs(offerForm.end_date) : null}
                                    onChange={(newValue) => handleDateChange('end_date', newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            margin="normal"
                                            error={!!error.end_date}
                                            helperText={error.end_date}
                                        />
                                    )}
                                />
                            </LocalizationProvider>

                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Descripción"
                                name="description"
                                value={offerForm.description}
                                onChange={handleChange}
                                margin="normal"
                                multiline
                                minRows={3}
                            />
                        </Grid>
                    </Grid>

                    <Typography component="h3" variant="h6" style={{ marginTop: '20px' }}>
                        Partes
                    </Typography>

                    <TableContainer component={Paper} style={{ marginTop: '10px' }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: '20%' }}>Nombre*</TableCell>
                                    <TableCell style={{ width: '30%' }}>Descripción</TableCell>
                                    <TableCell style={{ width: '15%' }}>Precio Unitario*</TableCell>
                                    <TableCell style={{ width: '15%' }}>Cantidad*</TableCell>
                                    <TableCell style={{ width: '15%' }}>Unidad*</TableCell>
                                    <TableCell style={{ width: '5%' }} align="center">Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {parts.map((part, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                value={part.name}
                                                onChange={(e) => handlePartsChange(index, 'name', e.target.value)}
                                                error={!!error[`part_${index}_name`]}
                                                helperText={error[`part_${index}_name`]}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                value={part.description}
                                                onChange={(e) => handlePartsChange(index, 'description', e.target.value)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                type="number"
                                                value={part.unit_price}
                                                onChange={(e) => handlePartsChange(index, 'unit_price', e.target.value)}
                                                error={!!error[`part_${index}_unit_price`]}
                                                helperText={error[`part_${index}_unit_price`]}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                type="number"
                                                value={part.quantity}
                                                onChange={(e) => handlePartsChange(index, 'quantity', e.target.value)}
                                                error={!!error[`part_${index}_quantity`]}
                                                helperText={error[`part_${index}_quantity`]}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <FormControl fullWidth error={!!error[`part_${index}_unit`]}>
                                                <InputLabel id={`unit-label-${index}`}>Unidad</InputLabel>
                                                <Select
                                                    labelId={`unit-label-${index}`}
                                                    name="unit"
                                                    fullWidth
                                                    value={part.unit}
                                                    onChange={(e) => handlePartsChange(index, 'unit', e.target.value)}
                                                    label="Unidad"
                                                >
                                                    <MenuItem value="uds">UDS</MenuItem>
                                                    <MenuItem value="kg">KG</MenuItem>
                                                    <MenuItem value="l">L</MenuItem>
                                                    <MenuItem value="hora">HORA</MenuItem>
                                                    <MenuItem value="m3">M3</MenuItem>
                                                </Select>
                                                {!!error[`part_${index}_unit`] && (
                                                    <FormHelperText>{error[`part_${index}_unit`]}</FormHelperText>
                                                )}
                                            </FormControl>
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton onClick={() => removePart(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box textAlign="center" marginTop={2}>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={addPart}
                        >
                            Añadir Parte
                        </Button>
                    </Box>

                    <Box textAlign="right" marginTop={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={24} /> : "Crear Oferta"}
                        </Button>
                    </Box>

                    {error.global && (
                        <Box textAlign="center" marginTop={2}>
                            <Typography color="error">
                                {error.global}
                            </Typography>
                        </Box>
                    )}
                </form>
            </Paper>
        </div>
    );
}

export default NewOffer;
